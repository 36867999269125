import moment from "moment";
import Swal from "sweetalert2";

export const dxDateEmpty = {
  methods: {
    inCaseEmptyDateDx(target) {
      let toDate = new Date(target.value);
      let formattedDate = moment(toDate).format("DD/MM/YYYY");

      if (toDate == "Invalid Date") {
        return " - ";
      } else {
        if (toDate.getFullYear() < 1970) {
          return " - ";
        } else {
          return formattedDate;
        }
      }
    }
  }
}

export const fileSizeChecker = {
  methods: {
    fileSizeChecker(maxSize, e) {
      let files = e.target.files;

      if (files.length > 0) {
        for (let i = 0; i < files.length; i++) {
          let fileSizeInMB = files[i].size / 1000000;

          if (fileSizeInMB > maxSize) {
            Swal.fire({
              title: "Error",
              text: `Maksimal ukuran file adalah ${maxSize} MB`,
              type: "error",
              confirmButtonText: "OK"
            });

            e.target.value = null;

            return false;
          }
        }
      }

      return true;
    }
  }
}

export const validateFileTypeGeneral = {
  methods: {
    validateFileTypeGeneral(e) {
      // Get the selected file from the input
      const files = e.target.files;

      // Check if a file was selected
      if (files.length == 0) {
        return false
      }

      const allowedTypes = [
        "application/pdf",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      ];

      for (let i = 0; i < files.length; i++) {
        if (!allowedTypes.includes(files[i].type)) {
          Swal.fire({
            title: "Error",
            text: `Tipe file tidak valid`,
            type: "error",
            confirmButtonText: "OK"
          });

          e.target.value = null;

          return false;
        }
      }

      return true;
    }
  }
}
