<template>
  <div>
    <div class="master-detail-caption">
      <b-button
        style="background: #0A65FF; border-radius: 4px; color: #ffffff; font-weight: 600"
        @click.prevent.stop="showModalAdd = true"
        :class="hasPermission('user/add') ? '' : 'hidden-button'"
      >
        <i class="fa fa-plus-circle" style="color: #ffffff;"></i>Tambah Progress</b-button
      >
    </div>
    <DxDataGrid
      :ref="dataGridRefKey"
      :data-source="dataSource"
      :show-borders="true"
      :column-auto-width="true"
      :word-wrap-enabled="true"
    >
      <DxScrolling
        :use-native="true"
        :scroll-by-content="true"
        :scroll-by-thumb="true"
        show-scrollbar="onHover"
      />
      <DxColumn data-field="status" />
      <DxColumn data-field="tentang" caption="Deskripsi" />
      <DxColumn
        data-field="tanggal"
        caption="Tanggal"
        :customize-text="inCaseEmptyDateDx"
      />
      <DxColumn
        :calculate-cell-value="downloadUrls"
        data-field="path"
        caption="Download"
        cell-template="cellTemplate"
        :allow-filtering="false"
      />
      <template #cellTemplate="{ data }">
        <div v-html="data.value"></div>
      </template>
      <DxColumn
        type="buttons"
        caption="Aksi"
        :visible="hasPermission('user/edit') || hasPermission('user/delete')"
      >
        <DxButton 
          icon="fa fa-trash" 
          hint="Hapus" 
          :onClick="onClickDelete"
          :visible="hasPermission('user/delete')"
        />
        <DxButton 
          icon="fa fa-edit" 
          hint="Ubah" 
          :onClick="onClickEdit"
          :visible="hasPermission('user/update')"
        />
      </DxColumn>
    </DxDataGrid>

    <!--   Modal Tambah Dokumentasi Progress  -->
    <b-modal
      v-model="showModalAdd"
      hide-footer
      centered
      size="lg"
      id="modal-tambah-dokumentasi"
    >
      <template #modal-header="{ close }">
        <h5 class="title-card-custom">Tambah Status</h5>
      </template>
      <b-form @submit.stop.prevent="onSubmit">
        <b-form-group
          id="status-input-group"
          label-for="input-status"
          label="Status"
        >
          <b-form-select v-model="form.status" :options="status" required>
            <template #first>
              <b-form-select-option :value="null" disabled
                >-- Pilih Salah Satu --</b-form-select-option
              >
            </template>
          </b-form-select>
        </b-form-group>

        <b-form-group
          id="deskripsi-input-group"
          label-for="input-deskripsi"
          label="Judul"
        >
          <b-form-textarea
            id="deskripsi"
            v-model="form.deskripsi"
            placeholder="Judul"
            rows="3"
            max-rows="6"
            required
          ></b-form-textarea>
        </b-form-group>

        <b-form-group id="url-input-group" label-for="input-url" label="URL">
          <b-form-input
            id="url"
            type="text"
            v-model="form.url"
            placeholder="URL"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          id="input-group-tanggal"
          label="Tanggal Progres"
          label-for="tanggal"
        >
          <b-form-datepicker
            id="tanggal"
            v-model="form.tanggal"
            class="mb-2"
            placeholder="Pilih tanggal progres"
            locale="id"
          ></b-form-datepicker>
        </b-form-group>

        <b-form-group id="path-group" label-for="path" label="Upload Produk" description="Maksimal ukuran file adalah 10MB (File yang dapat diupload: PDF, Docx)">
          <b-form-file
            ref="files"
            placeholder="Pilih File"
            drop-placeholder="Drop file di sini..."
            accept=".pdf, .doc, .docx"
            v-model="form.path"
            browse-text="Cari"
            multiple
            required
            :file-name-formatter="formatNames"
            @change="
              validateFileTypeGeneral($event);
              fileSizeChecker('10', $event)"
          ></b-form-file>
        </b-form-group>
        <hr />
        <div class="d-flex justify-content-center">
          <button type="reset" class="btn btn-delete mr-8" @click="resetForm()">
            Kembali
          </button>
          <button type="submit" class="btn btn-save">Simpan</button>
        </div>
      </b-form>
    </b-modal>

    <!--   Modal Edit Dokumentasi Progress  -->
    <b-modal
      v-model="showModalEditProgress"
      hide-footer
      centered
      size="lg"
      id="modal-edit-dokumentasi-progress"
    >
      <template #modal-header="{ close }">
        <h5 class="title-card-custom">Edit Status</h5>
      </template>
      <b-form @submit.stop.prevent="onSubmitEdit">
        <b-form-group
          id="status-input-group"
          label-for="input-status"
          label="Status"
        >
          <b-form-select v-model="formEdit.status" :options="status" required>
            <template #first>
              <b-form-select-option :value="null" disabled
                >-- Pilih Salah Satu --</b-form-select-option
              >
            </template>
          </b-form-select>
        </b-form-group>

        <b-form-group
          id="deskripsi-input-group"
          label-for="input-deskripsi"
          label="Deskripsi"
        >
          <b-form-textarea
            id="deskripsi"
            v-model="formEdit.tentang"
            placeholder="Deskripsi"
            rows="3"
            max-rows="6"
            required
          ></b-form-textarea>
        </b-form-group>

        <b-form-group id="url-input-group" label-for="input-url" label="URL">
          <b-form-input
            id="url"
            type="text"
            v-model="formEdit.url"
            placeholder="URL"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          id="input-group-tanggal-edit"
          label="Tanggal Progres"
          label-for="tanggal"
        >
          <b-form-datepicker
            id="tanggal"
            v-model="formEdit.tanggal"
            class="mb-2"
            placeholder="Pilih tanggal progres"
            locale="id"
          ></b-form-datepicker>
        </b-form-group>

        <b-form-group id="path-group" label-for="path" label="Upload Produk" description="Maksimal ukuran file adalah 10MB (File yang dapat diupload: PDF, Docx)">
          <b-form-file
            ref="files"
            placeholder="Pilih File"
            drop-placeholder="Drop file di sini..."
            accept=".pdf, .doc, .docx"
            v-model="formEdit.path"
            browse-text="Cari"
            multiple
            :file-name-formatter="formatNames"
            @change="
              validateFileTypeGeneral($event);
              fileSizeChecker('10', $event)"
          ></b-form-file>
        </b-form-group>
        <hr />
        <div class="d-flex justify-content-center">
          <button type="reset" class="btn btn-delete mr-8" @click="closeModal()">
            Kembali
          </button>
          <button type="submit" class="btn btn-save">Simpan</button>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>
<script>
import { DxDataGrid, DxColumn, DxButton, DxScrolling } from "devextreme-vue/data-grid";
import ArrayStore from "devextreme/data/array_store";
import DataSource from "devextreme/data/data_source";
import { handleErrors } from "@/core/appUtil/util";
import Swal from "sweetalert2";
import { mapGetters } from "vuex";
import moment from "moment";
import { dxDateEmpty } from "@/core/helpers/functions";
import {
  fileSizeChecker,
  validateFileTypeGeneral,
} from "@/core/helpers/functions";

let token = "";
const swalDelete = Swal.mixin({
  customClass: {
    confirmButton: "btn btn-delete",
    cancelButton: "btn btn-cancel"
  },
  buttonsStyling: false
});

const dataGridRefKey = "dokumentasi-progress-data-grid";

export default {
  mixins: [dxDateEmpty, fileSizeChecker, validateFileTypeGeneral],
  components: { DxDataGrid, DxColumn, DxButton, DxScrolling },
  props: {
    templateData: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      dataSource: this.getTasks(this.templateData.data.progress),
      showModalAdd: false,
      showModalEditProgress: false,
      dataGridRefKey,
      form: {
        status: "",
        path: null,
        deskripsi: "",
        tanggal: "",
        url: ""
      },
      formEdit: {
        status: "",
        path: null,
        tentang: "",
        url: "",
        id: null,
        tanggal: "",
        temp_path: null
      },
      status: [
        {
          text: "Mengubah",
          value: "Mengubah"
        },
        {
          text: "Mencabut",
          value: "Mencabut"
        },
        {
          text: "Uji Materi MK",
          value: "Uji Materi MK"
        }
      ]
    };
  },
  computed: {
    ...mapGetters({
      hasPermission: "token/hasPermission"
    }),
    dataGrid: function() {
      return this.$refs[dataGridRefKey].instance;
    }
  },
  mounted() {
    this.$store.dispatch("token/getAccessToken").then(access_token => {
      token = access_token;
    });
  },
  methods: {
    refreshData() {
      this.dataGrid.refresh();
    },
    downloadUrls(rowData) {
      let paths = rowData.path.split(";");
      let output = "";
      if (paths[0].length > 0) {
        output = `<ul>`;
        paths.forEach(path => {
          let fnames = path.split("/");
          let namaFile = fnames[1].substring(8);

          // START Check if new format file name
          let uniqueCode = fnames[1].split("-").pop();
          if (uniqueCode.length > 0) {
            uniqueCode = uniqueCode.split(".")[0];
          }

          let isUniqueCode = uniqueCode ? uniqueCode.length === 7 : false;
          if (isUniqueCode) {
            // Get string before extension
            namaFile = fnames[1].replace(`-${uniqueCode}`, "");
          }
          // END Check if new format file name

          output += `<li><a href="${process.env.VUE_APP_FILE_DOWNLOAD_PATH}/${path}" target="_blank">${namaFile}</a></li>`;
        });
        output += "</ul>";
      } else {
        output = `${rowData.path}`;
      }
      return output;
    },
    getTasks(theData) {
      return new DataSource({
        store: new ArrayStore({
          data: theData,
          key: "id"
        })
      });
    },
    formatNames(files) {
      return files.length === 1
        ? files[0].name
        : `${files.length} file terpilih`;
    },
    async onSubmit() {
      Swal.showLoading();

      // let id = this.form.code;
      if (this.form.path !== null) {
        let dataUpload = new FormData();
        for (const file of this.form.path) {
          dataUpload.append("files", file, file.name);
        }
        await fetch(
          encodeURI(process.env.VUE_APP_URL_LOCAL + `/file/multi-upload`),
          {
            method: "POST",
            headers: {
              Authorization: "bearer " + token
            },
            body: dataUpload
          }
        )
          .then(handleErrors)
          .then(response => response.json())
          .then(async dataHasilUpload => {
            let dokumentasiProgress = {
              dokumentasi_id: this.templateData.data.id,
              tentang: this.form.deskripsi,
              path: dataHasilUpload.data.join(";"),
              url: this.form.url,
              status: this.form.status,
              tanggal: this.form.tanggal ? moment.utc(this.form.tanggal, "YYYY-MM-DD").format() : null
            };
            await fetch(
              encodeURI(
                process.env.VUE_APP_URL_LOCAL + `/dokumentasi-progress`
              ),
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: "bearer " + token
                },
                body: JSON.stringify(dokumentasiProgress)
              }
            )
              .then(handleErrors)
              .then(response => response.json())
              .then(dataHasilProgress => {
                Swal.fire({
                  position: "center",
                  icon: dataHasilProgress.status,
                  title: "Data berhasil ditambahkan",
                  showConfirmButton: false,
                  timer: 1500
                });
                this.$emit("add-progress", dataHasilProgress);
              });
          });
      } else {
        let dokumentasiProgress = {
          dokumentasi_id: this.templateData.data.id,
          tentang: this.form.deskripsi,
          url: this.form.url,
          status: this.form.status,
          tanggal: this.form.tanggal ? moment.utc(this.form.tanggal, "YYYY-MM-DD").format() : null
        };
        await fetch(
          encodeURI(process.env.VUE_APP_URL_LOCAL + `/dokumentasi-progress`),
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: "bearer " + token
            },
            body: JSON.stringify(dokumentasiProgress)
          }
        )
          .then(handleErrors)
          .then(response => response.json())
          .then(dataHasilProgress => {
            Swal.fire({
              position: "center",
              icon: dataHasilProgress.status,
              title: "Data berhasil ditambahkan",
              showConfirmButton: false,
              timer: 1500
            });
            this.$emit("add-progress", dataHasilProgress);
          });
      }

      Swal.close();
    },
    async onSubmitEdit() {
      Swal.showLoading();

      let id = this.formEdit.id;

      if (this.formEdit.path !== null) {
        let dataUpload = new FormData();
        for (const file of this.formEdit.path) {
          dataUpload.append("files", file, file.name);
        }
        await fetch(
          encodeURI(process.env.VUE_APP_URL_LOCAL + `/file/multi-upload`),
          {
            method: "POST",
            headers: {
              Authorization: "bearer " + token
            },
            body: dataUpload
          }
        )
          .then(handleErrors)
          .then(response => response.json())
          .then(async dataHasilUpload => {
            let dokumentasiProgress = {
              dokumentasi_id: this.templateData.data.id,
              tentang: this.formEdit.tentang,
              path: dataHasilUpload.data.join(";"),
              url: this.formEdit.url,
              status: this.formEdit.status,
              tanggal: this.formEdit.tanggal ? moment.utc(this.formEdit.tanggal, "YYYY-MM-DD").format() : null
            };
            await fetch(
              encodeURI(
                process.env.VUE_APP_URL_LOCAL + `/dokumentasi-progress/${id}`
              ),
              {
                method: "PUT",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: "bearer " + token
                },
                body: JSON.stringify(dokumentasiProgress)
              }
            )
              .then(handleErrors)
              .then(response => response.json())
              .then(dataHasilProgress => {
                Swal.fire({
                  position: "center",
                  icon: dataHasilProgress.status,
                  title: "Data berhasil diubah",
                  showConfirmButton: false,
                  timer: 1500
                });
                this.$emit("add-progress", dataHasilProgress);
              });
          });
      } else {
        let dokumentasiProgress = {
          dokumentasi_id: this.templateData.data.id,
          tentang: this.formEdit.tentang,
          path: this.formEdit.temp_path,
          url: this.formEdit.url,
          status: this.formEdit.status,
          tanggal: this.formEdit.tanggal ? moment.utc(this.formEdit.tanggal, "YYYY-MM-DD").format() : null
        };

        await fetch(
          encodeURI(process.env.VUE_APP_URL_LOCAL + `/dokumentasi-progress/${id}`),
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Authorization: "bearer " + token
            },
            body: JSON.stringify(dokumentasiProgress)
          }
        )
          .then(handleErrors)
          .then(response => response.json())
          .then(dataHasilProgress => {
            Swal.fire({
              position: "center",
              icon: dataHasilProgress.status,
              title: "Data berhasil diubah",
              showConfirmButton: false,
              timer: 1500
            });
            this.$emit("add-progress", dataHasilProgress);
          });
      }

      Swal.close();
    },
    resetForm() {
      this.form = {
        status: null,
        deskripsi: "",
        path: null,
        url: ""
      };

      this.$nextTick(() => {
        this.$bvModal.hide("modal-tambah-dokumentasi");
      });
    },
    closeModal() {
      this.$nextTick(() => {
        this.$bvModal.hide("modal-edit-dokumentasi-progress");
      });
    },
    onClickEdit(e) {
      const data = e.row.data;

      this.formEdit.status = data.status;
      this.formEdit.tentang = data.tentang;
      this.formEdit.url = data.url;
      this.formEdit.temp_path = data.path;
      this.formEdit.id = data.id;

      let date = new Date(data.tanggal);
      this.formEdit.tanggal = date != "Invalid Date" && date.getFullYear() > 1970 ? data.tanggal : null;
      this.$root.$emit(
        "bv::show::modal",
        "modal-edit-dokumentasi-progress",
        e.row.data
      );
    },
    onClickDelete(e) {
      let id = e.row.data.id;
      swalDelete.fire({
        text: "Yakin ingin hapus data?",
        icon: "warning",
        iconHtml: "?",
        showCancelButton: true,
        confirmButtonText: "Hapus",
        cancelButtonText: "Batal",
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return fetch(
            encodeURI(
              process.env.VUE_APP_URL_LOCAL + `/dokumentasi-progress/${id}`
            ),
            {
              method: "DELETE",
              headers: {
                "Content-Type": "application/json",
                Authorization: "bearer " + token
              }
            }
          )
            .then(handleErrors)
            .then(response => response.json())
            .then(data => {
              console.log(data);
            })
            .catch(error => {
              console.log(error);
              Swal.fire({
                position: "center",
                icon: "success",
                title: "Data berhasil dihapus",
                showConfirmButton: false,
                timer: 1500
              });
              this.$emit("delete-progress", error);
            });
        },
        allowOutsideClick: () => !Swal.isLoading()
      });
    }
  }
};
</script>
<style>
.master-detail-caption {
  padding: 0 0 5px 10px;
  font-size: 14px;
  font-weight: bold;
  display: flex;
  justify-content: flex-end;
}
</style>
