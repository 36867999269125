<template>
  <div class="col-md-12">
    <b-card
      header-bg-variant="primary"
      header-text-variant="white"
      bg-variant="default"
      align="center"
      style="filter: drop-shadow(0px 2px 14px rgba(0, 0, 0, 0.15)); border-radius: 10px;"
    >
      <div class="justify-content-between d-flex">
        <div class="d-flex">
          <b-form>
            <b-form-group id="input-group-3" label="" label-for="input-3">
              <b-form-select
                id="input-3"
                v-model="form.tahun"
                style="background-color: #dae1ec"
                @change="onSelectTahun"
                :options="tahuns"
              >
                <template #first>
                  <b-form-select-option :value="null" disabled
                    >Tahun:</b-form-select-option
                  >
                </template>
              </b-form-select>
            </b-form-group>
          </b-form>

          <b-form
            @submit.prevent
            class="ml-5 mb-8"
            style="width: 300px; border-radius: 4px;"
          >
            <div
              class="input-group input-group-solid input-group-sm"
              style="background: #DAE1EC; height: 38px"
            >
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="fa fa-search"></i>
                </span>
              </div>
              <input
                type="text"
                class="form-control form-control-solid form-control-lg"
                name="email"
                autocomplete="off"
                placeholder="Search"
                v-model="form.search"
                ref="search"
                @keyup.enter="searchDocumentation()"
              />
              <button
                id="button-addon2"
                class="btn btn-cari"
                type="button"
                @click="searchDocumentation"
              >
                <span v-if="!loading"
                  ><span class="svg-icon svg-icon-sm">
                    <inline-svg src="/media/svg/custom/search.svg" />
                  </span>
                </span>
                <b-spinner medium v-else></b-spinner>
              </button>
            </div>
          </b-form>
        </div>
        <div class="d-flex">
          <button
            type="button"
            class="hidden-button"
            @click="refreshData"
            id="refreshButton"
            >
            <i class="fas fa-sync-alt"></i>
          </button>
          <router-link :to="{ name: 'TambahDokumentasi' }">
            <button
              :class="
                hasPermission('user/add')
                  ? 'btn btn-tambah-data'
                  : 'hidden-button'
              "
            >
              <i class="fa fa-plus-circle" style="color: #ffffff;"></i>
              Tambah Data
            </button>
          </router-link>
        </div>
      </div>
      <DxDataGrid
        :ref="dataGridRefKey"
        :data-source="dataSource"
        @selection-changed="selectionChanged"
        key-expr="id"
        :show-borders="true"
        :word-wrap-enabled="true"
        :column-auto-width="true"
        :remote-operations="true"
        :column-hiding-enabled="true"
      >
        <DxScrolling
          :use-native="true"
          :scroll-by-content="true"
          :scroll-by-thumb="true"
          show-scrollbar="onHover"
        />
        <DxSelection mode="single" />
        <DxColumn data-field="id" sort-order="desc" :visible="false" />
        <DxColumn data-field="tahun" caption="Tahun" :allow-filtering="false" />
        <DxColumn
          data-field="jenis_perundangan.name"
          caption="Jenis Produk Hukum"
          :allow-filtering="false"
        />
        <DxColumn
          data-field="judul"
          caption="Judul"
          :allow-filtering="false"
          :filter-operations="['contains']"
        />
        <!--        <DxColumn
          data-field="nama"
          caption="Nama Produk Hukum"
          :filter-operations="['contains']"
        />-->
        <DxColumn
          data-field="sinopsis"
          caption="Sinopsis"
          :allow-filtering="false"
        />
        <DxColumn
          :calculate-cell-value="downloadUrls"
          data-field="produk_path"
          caption="Download Produk"
          cell-template="cellTemplatePath"
          :allow-filtering="false"
        />
        <template #cellTemplatePath="{ data }">
          <div v-html="data.value"></div>
        </template>
        <DxMasterDetail :enabled="true" template="masterDetailDokumentasi" />
        <template #masterDetailDokumentasi="{ data: dokumentasi }">
          <DokumentasiProgress
            :template-data="dokumentasi"
            @add-progress="refreshData"
            @delete-progress="refreshData"
          />
        </template>
        <DxColumn :width="100" type="buttons" caption="Aksi">
          <DxButton
            icon="fa fa-eye"
            hint="Lihat"
            :onClick="btnView"
            :visible="hasPermission('user/view')"
          />
          <DxButton
            icon="fa fa-trash"
            hint="Hapus"
            :onClick="btnDelete"
            :visible="hasPermission('user/delete')"
          />
          <DxButton
            icon="fa fa-edit"
            hint="Edit"
            :onClick="btnEdit"
            :visible="hasPermission('user/update')"
          />
        </DxColumn>
        <DxPaging :page-size="10" />
        <DxPager
          :visible="true"
          :show-page-size-selector="showPageSizeSelector"
          :allowed-page-sizes="pageSizes"
        />
      </DxDataGrid>
    </b-card>

    <!--   Modal Edit Dokumentasi   -->

    <b-modal
      v-model="showModalEdit"
      hide-footer
      centered
      size="xl"
      id="modal-edit-dokumentasi"
    >
      <template #modal-header="{ close }">
        <h5 class="title-card-custom">Edit Dokumentasi</h5>
      </template>
      <b-form @submit.stop.prevent="onSubmitEdit">
        <b-form-group id="input-group-1" label="Tahun" label-for="year">
          <b-form-input
            id="year"
            v-model="formEdit.year"
            type="text"
            placeholder="Tahun"
            required
          ></b-form-input>
        </b-form-group>

        <b-form-group
          id="input-group-2"
          label="Jenis Produk Hukum"
          label-for="jenis"
        >
          <b-form-select v-model="formEdit.type" :options="types" class="mb-3" required>
            <template #first>
              <b-form-select-option :value="null" disabled
                >-- Pilih salah satu --</b-form-select-option
              >
            </template>
          </b-form-select>

          <!--            <b-form-input
              id="jenis"
              v-model="form.jenis"
              placeholder="Masukkan jenis produk hukum"
              v-if="form.type === 'O'"
              v-show="form.type"
          ></b-form-input>-->
        </b-form-group>

        <b-form-group id="input-group-3" label="Judul" label-for="title">
          <b-form-textarea
            id="title"
            v-model="formEdit.judul"
            placeholder="Judul"
            rows="3"
            max-rows="6"
            required
          ></b-form-textarea>
        </b-form-group>

        <!--        <b-form-group
          id="input-group-4"
          label-for="product"
          label="Nama Produk Hukum"
        >
          <b-form-textarea
            id="textarea"
            v-model="formEdit.nama"
            placeholder="Nama Produk Hukum"
            rows="3"
            max-rows="6"
          ></b-form-textarea>
        </b-form-group>-->

        <b-form-group
          id="input-group-5"
          label="Upload Produk"
          label-for="input-5"
          description="Maksimal ukuran file adalah 10MB (File yang dapat diupload: PDF, Docx)"
        >
          <b-form-file
            placeholder="Pilih File"
            drop-placeholder="Drop file di sini..."
            accept=".pdf, .doc, .docx"
            v-model="formEdit.path"
            browse-text="Cari"
            multiple
            :file-name-formatter="formatNames"
            @change="
              validateFileTypeGeneral($event);
              fileSizeChecker('10', $event)"
          ></b-form-file>
        </b-form-group>

        <b-form-group id="input-group-6" label="Sinopsis" label-for="input-6">
          <b-form-textarea
            id="sinopsis"
            v-model="formEdit.sinopsis"
            placeholder="Sinopsis"
            rows="3"
            max-rows="6"
          ></b-form-textarea>
        </b-form-group>
        <hr />
        <div class="d-flex justify-content-center">
          <button
            type="reset"
            class="btn btn-delete mr-7"
            @click="closeModal()"
          >
            Kembali
          </button>
          <button type="submit" class="btn btn-save">
            Simpan
          </button>
        </div>
      </b-form>
    </b-modal>

    <!--   Modal View Dokumentasi   -->

    <b-modal
      v-model="showModalView"
      hide-footer
      centered
      size="xl"
      id="modal-view-dokumentasi"
    >
      <template #modal-header="{ close }">
        <h5 class="title-card-custom">Lihat Dokumentasi</h5>
      </template>
      <b-form @submit.stop.prevent="onSubmitEdit">
        <b-form-group id="input-group-1" label="Tahun" label-for="year">
          <b-form-input
            id="year"
            v-model="formView.year"
            type="text"
            placeholder="Tahun"
            disabled
          ></b-form-input>
        </b-form-group>

        <b-form-group
          id="input-group-2"
          label="Jenis Produk Hukum"
          label-for="jenis"
        >
          <b-form-select
            v-model="formView.type"
            :options="types"
            class="mb-3"
            disabled
          >
            <template #first>
              <b-form-select-option :value="null" disabled
                >-- Pilih salah satu --</b-form-select-option
              >
            </template>
          </b-form-select>

          <!--            <b-form-input
              id="jenis"
              v-model="form.jenis"
              placeholder="Masukkan jenis produk hukum"
              v-if="form.type === 'O'"
              v-show="form.type"
          ></b-form-input>-->
        </b-form-group>

        <b-form-group id="input-group-3" label="Judul" label-for="title">
          <b-form-input
            id="title"
            v-model="formView.judul"
            placeholder="Judul"
            disabled
          ></b-form-input>
        </b-form-group>

        <!--        <b-form-group
          id="input-group-4"
          label-for="product"
          label="Nama Produk Hukum"
        >
          <b-form-textarea
            id="textarea"
            v-model="formEdit.nama"
            placeholder="Nama Produk Hukum"
            rows="3"
            max-rows="6"
          ></b-form-textarea>
        </b-form-group>-->

        <b-form-group
          id="input-group-5"
          label="Upload Produk"
          label-for="input-5"
        >
          <b-form-file
            placeholder="Pilih File"
            drop-placeholder="Drop file di sini..."
            accept=".pdf, .doc, .docx"
            v-model="formView.path"
            browse-text="Cari"
            multiple
            disabled
            :file-name-formatter="formatNames"
            @change="
              validateFileTypeGeneral($event);
              fileSizeChecker('10', $event)"
          ></b-form-file>
        </b-form-group>

        <b-form-group id="input-group-6" label="Sinopsis" label-for="input-6">
          <b-form-textarea
            id="sinopsis"
            v-model="formView.sinopsis"
            placeholder="Sinopsis"
            rows="3"
            disabled
            max-rows="6"
          ></b-form-textarea>
        </b-form-group>
        <hr />
        <div class="d-flex justify-content-center">
          <button
            type="reset"
            class="btn btn-delete mr-7"
            @click="closeModalView()"
          >
            Tutup
          </button>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import {
  DxButton,
  DxColumn,
  DxDataGrid,
  DxPager,
  DxPaging,
  DxMasterDetail,
  DxSelection,
  DxScrolling
} from "devextreme-vue/data-grid";
import CustomStore from "devextreme/data/custom_store";
import { handleErrors, isNotEmpty } from "@/core/appUtil/util";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import "whatwg-fetch";
import Swal from "sweetalert2";
import DokumentasiProgress from "@/view/pages/admin/dokumentasi/DokumentasiProgress";
import { mapGetters } from "vuex";
import {
  fileSizeChecker,
  validateFileTypeGeneral,
} from "@/core/helpers/functions";

let tahunQ = "0";
let jenisQ = "";
let searchQ = "";
const store = new CustomStore({
  key: "id",
  load: function(loadOptions) {
    let params = "?";
    [
      "skip",
      "take",
      "requireTotalCount",
      "requireGroupCount",
      "sort",
      // "filter",
      "totalSummary",
      "group",
      "groupSummary"
    ].forEach(function(i) {
      if (i in loadOptions && isNotEmpty(loadOptions[i])) {
        params += `${i}=${JSON.stringify(loadOptions[i])}&`;
      }
    });
    params = params.slice(0, -1);

    // search by name if exists
    if (tahunQ !== "0") {
      params += "&tahun=" + tahunQ;
    }

    jenisQ = parseInt(window.location.pathname.split("/").pop())
    if (jenisQ !== "" && typeof jenisQ === "number") {
      params += "&jenis_perundangan_id=" + jenisQ;
    }

    if (searchQ !== "") {
      params += `&q=${searchQ}`;
    }

    console.log(params)

    // if (
    //   Array.isArray(loadOptions["filter"]) &&
    //   loadOptions["filter"].length > 0 &&
    //   loadOptions["filter"][0] === "judul"
    // ) {
    //   params += "&judul=" + loadOptions["filter"][2];
    // }

    return fetch(process.env.VUE_APP_URL_LOCAL + `/dokumentasi${params}`)
      .then(response => response.json())
      .then(data => {
        if (data.data === null) {
          setTimeout(() => {
            document.querySelector("#refreshButton").click();
          }, 500);

        } else {
          return {
            data: data.data,
            totalCount: data.totalCount,
            summary: data.summary,
            groupCount: data.groupCount
          };
        }

      })
      .catch(() => {
        throw "Data Loading Error";
      });
  }
});

let token = "";

const dataGridRefKey = "my-data-grid";

const swalDelete = Swal.mixin({
  customClass: {
    confirmButton: "btn btn-delete",
    cancelButton: "btn btn-cancel"
  },
  buttonsStyling: false
});

const swalSuccess = Swal.mixin({
  customClass: {
    confirmButton: "btn btn-save",
    cancelButton: "btn btn-delete"
  },
  buttonsStyling: false
});

export default {
  mixins: [validationMixin, fileSizeChecker, validateFileTypeGeneral],
  name: "TabelDokumentasi",
  components: {
    DokumentasiProgress,
    DxDataGrid,
    DxPaging,
    DxPager,
    DxColumn,
    DxButton,
    DxMasterDetail,
    DxSelection,
    DxScrolling
  },
  data() {
    return {
      showModalAdd: false,
      showModalEdit: false,
      showModalView: false,
      isValid: false,
      form: {
        tahun: null,
        jenis: null,
        search: null,
      },
      formEdit: {
        year: null,
        judul: "",
        nama: "",
        sinopsis: "",
        others: "",
        path: null,
        jenis: "",
        type: null,
        temp_path: null,
      },
      formView: {
        year: null,
        judul: "",
        nama: "",
        sinopsis: "",
        others: "",
        path: null,
        jenis: "",
        type: null
      },
      types: [],
      tahuns: [
        {
          text: "Semua",
          value: "0"
        },
        // {
        //   text: "2021",
        //   value: "2021"
        // },
        // {
        //   text: "2020",
        //   value: "2020"
        // }
      ],
      dataSource: store,
      dataGridRefKey,
      showPageSizeSelector: true,
      pageSizes: [5, 10, 20],
      jenis_perundangan: {
        name: ""
      },
      loading: false,
    };
  },
  validations: {
    form: {
      name: {
        required
      }
    }
  },
  computed: {
    ...mapGetters({
      hasPermission: "token/hasPermission"
    }),
    dataGrid: function() {
      return this.$refs[dataGridRefKey].instance;
    }
  },
  mounted() {
    // for (let i = new Date().getFullYear(); i >= 2020; i--) {
    //   this.tahuns.push({
    //     text: i,
    //     value: i
    //   });
    // }

    this.setTahunsDropdown()

    this.$store.dispatch("token/getAccessToken").then(access_token => {
      token = access_token;
    });
    fetch(
      encodeURI(
        process.env.VUE_APP_URL_LOCAL + `/master/jenis-perundangan?take=100`
      ),
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "bearer " + token
        }
      }
    )
      .then(handleErrors)
      .then(response => response.json())
      .then(items => {
        const detailMenu = items.data;
        let selectedOptions;
        selectedOptions = [];

        if (detailMenu) {
          detailMenu.map(i => {
            return selectedOptions.push({
              text: i.name,
              value: i.id
            });
          });
        }

        this.types = selectedOptions;
      });
  },
  methods: {
    selectionChanged(e) {
      e.component.collapseAll(-1);
      e.component.expandRow(e.currentSelectedRowKeys[0]);
    },
    uppercase() {
      this.formEdit.judul = this.formEdit.judul.toUpperCase();
    },
    downloadUrls(rowData) {
      let paths = rowData.produk_path.split(";");
      let output = "";
      if (paths[0].length > 0) {
        output = `<ul>`;
        paths.forEach(path => {
          let fnames = path.split("/");
          let namaFile = fnames[1].substring(8);

          // START Check if new format file name
          let uniqueCode = fnames[1].split("-").pop();
          if (uniqueCode.length > 0) {
            uniqueCode = uniqueCode.split(".")[0];
          }

          let isUniqueCode = uniqueCode ? uniqueCode.length === 7 : false;
          if (isUniqueCode) {
            // Get string before extension
            namaFile = fnames[1].replace(`-${uniqueCode}`, "");
          }
          // END Check if new format file name

          output += `<li><a href="${process.env.VUE_APP_FILE_DOWNLOAD_PATH}/${path}" target="_blank">${namaFile}</a></li>`;
        });
        output += "</ul>";
      } else {
        output = `${rowData.produk_path}`;
      }
      return output;
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    validateStateFormEdit(name) {
      const { $dirty, $error } = this.$v.formEdit[name];
      return $dirty ? !$error : null;
    },
    closeModal() {
      this.$nextTick(() => {
        this.$bvModal.hide("modal-edit-dokumentasi");
      });
    },
    closeModalView() {
      this.$nextTick(() => {
        this.$bvModal.hide("modal-view-dokumentasi");
      });
    },
    resetForm() {
      this.form = {
        name: null,
        icon: null
      };

      this.$nextTick(() => {
        this.$bvModal.hide("modal-tambah-dokumentasi");
      });
    },
    resetFormEdit() {
      this.formEdit = {
        year: null,
        judul: "",
        nama: "",
        sinopsis: "",
        others: "",
        path: null,
        jenis: "",
        type: null
      };

      this.$nextTick(() => {
        this.$bvModal.hide("modal-edit-dokumentasi");
      });
    },
    onSelectTahun() {
      tahunQ = this.form.tahun;
      this.refreshData();
    },
    onSubmitEdit() {
      let id = this.formEdit.id;

      swalSuccess.fire({
        title: "Kirim Berkas",
        text: "Pastikan kembali data sudah sesuai.",
        icon: "warning",
        iconHtml: "?",
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonColor: "#18328D",
        confirmButtonText: "Simpan",
        cancelButtonText: "Batal",
        showLoaderOnConfirm: true,
        preConfirm: () => {
          if (this.formEdit.path !== null) {
            let dataUpload = new FormData();

            for (const file of this.formEdit.path) {
              dataUpload.append("files", file, file.name);
            }

            return fetch(
              encodeURI(process.env.VUE_APP_URL_LOCAL + `/file/multi-upload`),
              {
                method: "POST",
                headers: {
                  Authorization: "bearer " + token
                },
                body: dataUpload
              }
            )
              .then(handleErrors)
              .then(response => response.json())
              .then(async dataHasilUpload => {
                let data = {
                  tahun: Number(this.formEdit.year),
                  nama: this.formEdit.nama,
                  judul: this.formEdit.judul,
                  sinopsis: this.formEdit.sinopsis,
                  produk_path: dataHasilUpload.data.join(";"),
                  jenis_perundangan_id: this.formEdit.type
                };

                await fetch(
                  encodeURI(
                    process.env.VUE_APP_URL_LOCAL + `/dokumentasi/${id}`
                  ),
                  {
                    method: "PUT",
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: "bearer " + token
                    },
                    body: JSON.stringify(data)
                  }
                )
                  .then(handleErrors)
                  .then(response => response.json())
                  .then(() => {
                    Swal.fire({
                      position: "center",
                      icon: "success",
                      title: "Data berhasil diubah",
                      showConfirmButton: false,
                      timer: 1500
                    });
                    this.resetFormEdit();
                    this.refreshData();
                  })
                  .catch(error => {
                    console.error(error);
                    Swal.fire({
                      title: "Kesalahan",
                      text: "Gagal mengubah data",
                      icon: "error",
                      showCancelButton: false,
                      confirmButtonText: "Kembali",
                      confirmButtonColor: "#18328D"
                    });
                  });
              });

          } else {
            let data = {
              tahun: Number(this.formEdit.year),
              nama: this.formEdit.nama,
              judul: this.formEdit.judul,
              sinopsis: this.formEdit.sinopsis,
              produk_path: this.formEdit.temp_path,
              jenis_perundangan_id: this.formEdit.type
            };

            return fetch(
              encodeURI(process.env.VUE_APP_URL_LOCAL + `/dokumentasi/${id}`),
              {
                method: "PUT",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: "bearer " + token
                },
                body: JSON.stringify(data)
              }
            )
              .then(handleErrors)
              .then(response => response.json())
              .then(() => {
                Swal.fire({
                  position: "center",
                  icon: "success",
                  title: "Data berhasil diubah",
                  showConfirmButton: false,
                  timer: 1500
                });
                this.resetFormEdit();
                this.refreshData();
              })
              .catch(error => {
                console.error(error);
                Swal.fire({
                  title: "Kesalahan",
                  text: "Gagal mengubah data",
                  icon: "error",
                  showCancelButton: false,
                  confirmButtonText: "Kembali",
                  confirmButtonColor: "#18328D"
                });
              });
          }
        },
        allowOutsideClick: () => !Swal.isLoading()
      });
    },
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }

      let data = {
        id: null,
        icon: this.form.icon,
        name: this.form.name
      };

      swalSuccess.fire({
        title: "Kirim Berkas",
        text: "Pastikan kembali data sudah sesuai.",
        icon: "warning",
        iconHtml: "?",
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonColor: "#18328D",
        confirmButtonText: "Simpan",
        cancelButtonText: "Batal",
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return fetch(
            encodeURI(process.env.VUE_APP_URL_LOCAL + `/dokumentasi`),
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: "bearer " + token
              },
              body: JSON.stringify(data)
            }
          )
            .then(handleErrors)
            .then(response => response.json())
            .then(() => {
              Swal.fire({
                position: "center",
                icon: "success",
                title: "Data berhasil ditambah",
                showConfirmButton: false,
                timer: 1500
              });
              this.resetForm();
              this.refreshData();
            })
            .catch(error => {
              console.error(error);
              Swal.fire({
                title: "Kesalahan",
                text: "Data yang ditambah sudah ada",
                icon: "error",
                showCancelButton: false,
                confirmButtonText: "Kembali",
                confirmButtonColor: "#18328D"
              });
            });
        },
        allowOutsideClick: () => !Swal.isLoading()
      });
    },
    refreshData() {
      this.loading = true;
      this.dataGrid.refresh();
      this.loading = false;
    },
    btnDelete(e) {
      let id = e.row.data.id;
      swalDelete.fire({
        text: "Yakin ingin hapus data?",
        icon: "warning",
        iconHtml: "?",
        showCancelButton: true,
        confirmButtonText: "Hapus",
        cancelButtonText: "Batal",
        showLoaderOnConfirm: true,
        preConfirm: () => {
          return fetch(
            encodeURI(process.env.VUE_APP_URL_LOCAL + `/dokumentasi/${id}`),
            {
              method: "DELETE",
              headers: {
                "Content-Type": "application/json",
                Authorization: "bearer " + token
              }
            }
          )
            .then(handleErrors)
            .then(response => response.json())
            .then(() => {
              // console.log(data);
            })
            .catch(error => {
              console.error(error);
              Swal.fire({
                position: "center",
                icon: "success",
                title: "Data berhasil dihapus",
                showConfirmButton: false,
                timer: 1500
              });
              this.refreshData();
            });
        },
        allowOutsideClick: () => !Swal.isLoading()
      });
    },
    btnEdit(e) {
      const data = e.row.data;
      this.formEdit.year = data.tahun;
      this.formEdit.nama = data.nama;
      this.formEdit.judul = data.judul;
      this.formEdit.id = data.id;
      this.formEdit.sinopsis = data.sinopsis;
      this.formEdit.jenis = data.jenis;
      this.formEdit.temp_path = data.produk_path;
      this.formEdit.type = data.jenis_perundangan_id;
      this.$root.$emit("bv::show::modal", "modal-edit-dokumentasi", e.row.data);
    },
    btnView(e) {
      const data = e.row.data;

      this.formView.year = data.tahun;
      this.formView.nama = data.nama;
      this.formView.judul = data.judul;
      this.formView.id = data.id;
      this.formView.sinopsis = data.sinopsis;
      this.formView.jenis = data.jenis;
      this.formView.path = data.produk_path;
      this.formView.type = data.jenis_perundangan_id;
      this.$root.$emit("bv::show::modal", "modal-view-dokumentasi", e.row.data);
    },
    formatNames(files) {
      return files.length === 1
        ? files[0].name
        : `${files.length} file terpilih`;
    },
    setTahunsDropdown() {
      fetch(
        encodeURI(process.env.VUE_APP_URL_LOCAL + `/dokumentasi/years`), {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: "bearer " + token
          }
        }
      )
        .then(handleErrors)
        .then(response => response.json())
        .then(items => {
          const years = items.data;

          if (years) {
            years.sort((n1, n2) => n1 - n2)

            years.map(i => {
              return this.tahuns.push({
                text: i,
                value: i
              });
            });
          }
        });
    },
    async searchDocumentation() {
      searchQ = this.form.search;
      this.refreshData();
    },
  },
  watch: {
    '$route.params.id': function () {
      this.refreshData();
    }
  },
};
</script>

<style scoped>
.hidden-button {
  display: none;
}
</style>
